import { PropsWithChildren } from 'react';
import './Link.css';

interface LinkProps {
  href?: string;
  alt?: string;
  className?: string;
  target?: string;
}

const Link = ({ children, href='#', alt, className, target }: PropsWithChildren<LinkProps>) => {
  return (
    <a className={'Link ' + className} href={href} target={target} rel="noreferrer">
        { children }
    </a>
  );
}

export default Link;
