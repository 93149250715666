import { useTranslation } from 'react-i18next';
import { Link, Events, scroller } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav } from '../';
import './Sidebar.css';

interface SidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Sidebar = ({ open, setOpen }: SidebarProps) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <div className={'Sidebar ' + (open ? 'Sidebar_open' : 'Sidebar_close')} onClick={() => setOpen(!open)}>
      <Navbar vertical>
        <Nav value={t('nav.home')} onClick={() => {
            navigate('/');
        }}/>

        <Nav value={t('nav.coworking')} onClick={() => {
          navigate('/');

          setTimeout(() => {
            scroller.scrollTo('coworking', {
              delay: 100,
              smooth: 'easeInOutQuint',
            });
          })
        }}/>

        <Nav value={t('nav.coffeeshop')} onClick={() => {
          navigate('/');

          setTimeout(() => {
            scroller.scrollTo('coffeeshop', {
              delay: 100,
              smooth: 'easeInOutQuint',
            });
          })
        }}/>

        <Nav value={t('nav.events')} onClick={() => navigate('/events')}/>

        <Nav value={t('nav.about')} onClick={() => {
            navigate('/about');
        }}/>

        <Nav value={t('nav.contacts')} onClick={() => {
          navigate('/');

          setTimeout(() => {
            scroller.scrollTo('contacts', {
              delay: 100,
              smooth: 'easeInOutQuint',
            });
          })
        }}/>
      </Navbar>
    </div>
  );
}

export default Sidebar;
