import { PropsWithChildren } from 'react';
import './Navbar.css';

interface NavbarProps {
  vertical?: boolean;
  className?: string;
}

const Navbar = ({ children, vertical, className }: PropsWithChildren<NavbarProps>) => {
  return (
    <ul className={className + ' Navbar ' + (vertical ? 'Navbar_vertical' : 'Navbar_horisontal')}>
        { children }
    </ul>
  );
}

export default Navbar;
