import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import './Map.css';

const MyMap = () => {
  const defaultState = {
    center: [40.186707,44.509903],
    zoom: 16,
  };

  return (
    <div className='Map'>
      <YMaps>
        <Map defaultState={defaultState} width="100%">
          <Placemark geometry={[40.186707,44.509903]} />
        </Map>
      </YMaps>
    </div>
  );
}

export default MyMap;
