import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, scroller } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { LanguageSwitch, Navbar, Nav, Logo, Burger, Sidebar } from '../';
import './Header.css';

const Header = () => {
  const { t } = useTranslation();
  const [ open, setOpen ] = useState(false);

  const [offset, setOffset] = useState(0);

  let navigate = useNavigate();

  useEffect(() => {
    const onScroll = () => {
      return setOffset(window.pageYOffset);
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <header className={"Header " + (offset > 0 ? "Header_scroll" : '')}>
      <div className="Header__burger">
        <Burger { ...{open, setOpen} }/>
        <div className="Header__sidebar">
          <Sidebar { ...{open, setOpen} }/>
        </div>
      </div>

      <div className={"Header__logo"}>
        <Link to="home" spy={true} smooth={true} duration={500} className="element" offset={-100}>
          <Logo className={"Header__logo " + (offset > 0 ? "Header__logo_scroll" : '')}/>
        </Link>
      </div>

      <div className="Header__controls">
        <div className="Header__navbar">
          <Navbar>
          <Nav value={t('nav.home')} onClick={() => {
            navigate('/');
        }}/>

        <Nav value={t('nav.coworking')} onClick={() => {
          navigate('/');

          setTimeout(() => {
            scroller.scrollTo('coworking', {
              delay: 100,
              smooth: 'easeInOutQuint',
            });
          })
        }}/>

        <Nav value={t('nav.coffeeshop')} onClick={() => {
          navigate('/');

          setTimeout(() => {
            scroller.scrollTo('coffeeshop', {
              delay: 100,
              smooth: 'easeInOutQuint',
            });
          })
        }}/>

        <Nav value={t('nav.events')} onClick={() => navigate('/events')}/>

        <Nav value={t('nav.about')} onClick={() => {
            navigate('/about');
        }}/>

        <Nav value={t('nav.contacts')} onClick={() => {
          navigate('/');

          setTimeout(() => {
            scroller.scrollTo('contacts', {
              delay: 100,
              smooth: 'easeInOutQuint',
            });
          })
        }}/>
          </Navbar>
        </div>
        <div className="Header__LanguageSwitch">
          <LanguageSwitch langs={['en', 'ru']}/>
        </div>
      </div>
    </header>
  );
}

export default Header;
