const en = {
    translation: {
        head: {
            title: 'Letters and numbers | Coworking Space | Coffee shop | Events | Yerevan',
            description: 'Letters and numbers - inspiring coworking space and coffee shop for work in the center of Yerevan.'
        },
        home: {
            title: 'Letters and numbers - inspiring coworking space and coffee shop for work in the center of Yerevan.'
        },
        nav: {
        home: 'home',
        coworking: 'coworking',
        coffeeshop: 'coffee shop',
        events: 'events',
        about: 'about us',
        contacts: 'contacts'
        },
        coworking: {
            title1: 'coworking in a unique location',
            desc1: 'Our location allows you to be in a private and hidden place at the same time, on the other hand — you are in the heart of the city, so you can get to the main locations very quickly and also quickly return to the coworking room and continue your work. The private garden and outdoor terraces are a great option to take a walk with a mug of warming coffee in any weather and disconnect from the hustle and bustle for a while.',
            title2: 'Amenities',
            desc2: 'Almost classical interior of the coworking room creates a business-like atmosphere, isolation from other rooms allows you to concentrate on the main thing. Our work tables are designed for comfortable seating with a laptop, a mug and everything you need. Dedicated high-speed internet up to 100 Mpbs is available throughout the room. Energizing coffee, made from freshly roasted beans from local roasters, is already included in the price of the coworking space and is unlimited for you.',
            title3: 'For team or individual work',
            desc3: 'Our coworking room is not just a place where you can work in comfort, but also a place where you can find people who are like you, ready to share experiences, create and develop something new and thus improve each other`s results. We`ll provide the most comfortable places for teams to work together. Also our staff will be able to tell you how to solve the main issues that emerge in a new place.',
            title4: 'Prices',
            coworkingPricesTable: {
                title: 'Prices',
                desc1: '* – freezing of days is possible',
                desc2: '** – we have a lot of free places for calls and meetings at outdoor venues',
                col1: {
                    title: '',
                    row1: 'Price, ֏',
                    row2: 'Meeting\n room**',
                    row3: 'Fixed\n desc',
                    row4: 'Filter-coffee\n or tea',
                    row5: 'Printout,\n scan',
                    row6: 'Luggage\n storage',
                },
                col2: {
                    title: '1 hour',
                    row1: '1 300',
                    row2: '—',
                    row3: '—',
                    row4: '—',
                    row5: '—',
                    row6: '—',
                    row7: '—'
                },
                col3: {
                    title: '4 hours',
                    row1: '3 000',
                    row2: '30 min',
                    row3: '—',
                    row4: 'yes',
                    row5: 'yes',
                    row6: 'yes',
                    row7: 'yes'
                },
                col4: {
                    title: '1 day',
                    row1: '5 000',
                    row2: '30 min',
                    row3: '—',
                    row4: 'yes',
                    row5: 'yes',
                    row6: 'yes',
                    row7: 'yes'
                },
                col5: {
                    title: '7 days*',
                    row1: '25 000',
                    row2: '2 hours',
                    row3: 'yes',
                    row4: 'yes',
                    row5: 'yes',
                    row6: 'yes',
                    row7: 'yes'
                },
                col6: {
                    title: '30 days*',
                    row1: '75 000',
                    row2: '10 hours',
                    row3: 'yes',
                    row4: 'yes',
                    row5: 'yes',
                    row6: 'yes',
                    row7: 'yes'
                },
            },
            meetingRoomPricesTable: {
                title: 'MEETING ROOM PRICES (AMD)',
                col1: {
                    title: 'DURATION',
                    row1: 'Up to 30 min',
                    row2: '1 — 3 hours',
                    row3: '4 hours',
                    row4: '8 hours',
                    row5: '12 hours',
                },
                col2: {
                    title: '',
                    row1: '—',
                    row2: 'per person',
                    row3: 'whole room',
                    row4: 'whole room',
                    row5: 'whole room',
                },
                col3: {
                    title: 'FOR RESIDENT',
                    row1: '0',
                    row2: '1 000',
                    row3: '11 000',
                    row4: '16 000',
                    row5: '22 000',
                },
                col4: {
                    title: 'FOR NON-RESIDENT',
                    row1: '—',
                    row2: '3 500',
                    row3: '15 000',
                    row4: '24 000',
                    row5: '28 000',
                },
            },
            coworkingOptions: {
                title: 'COWORKING OPTIONS:',
                col1: {
                    title: 'COWORKING',
                    row1: 'weekdays\n10:00-22:00\nweekends\n10:00-16:00',
                    row2: 'luggage\nstorage',
                    row3: 'private courtyard\nwith garden',
                },
                col2: {
                    title: 'ERGONOMIC',
                    row1: 'furniture',
                    row2: 'separate\nmeeting room',
                    row3: 'a place to eat',
                },
                col3: {
                    title: 'UNLIMITED',
                    row1: 'specialty\nfilter-coffee\nor tea',
                    row2: 'air\nconditioning',
                    row3: 'a rich cultural\nprogram of events',
                },
                col4: {
                    title: 'HIGH-SPEED',
                    row1: 'internet',
                    row2: 'natural and\nartificial\nlighting',
                    row3: 'all types of\npayment:\ncash, card,\nbank account',
                },
                col5: {
                    title: 'PRINTER,\nSCANNER',
                    row1: 'printer, scanner',
                    row2: 'outdoor\nterraces\nfor work',
                    row3: 'special\nconditions\nfor teams and\ncorporate\nclients',
                },
            }
        },  
        coffeeshop: {
            title1: 'You can get coffee to go as well as to sit in the coffee house hall',
            title2: 'A few facts:',
            listItem1: 'We use freshly roasted beans.',
            listItem2: 'We work with the best coffee roasters and specialists in our industry.',
            listItem3: 'We have experienced baristas who are ready to make both classic drinks and to experiment based on your choice.',
            listItem4: 'We use purified water and professional water treatment equipment. Our water is free for everyone and in any quantity.',
            listItem5: 'We are pet-friendly.',
            title3: 'The basic coffee list:',
            coffeeItem1: 'Espresso',
            coffeeItem2: 'Americano',
            coffeeItem3: 'Cappuccino',
            coffeeItem4: 'Latte',
            coffeeItem5: 'Flat White',
            coffeeItem6: 'Raf',
            coffeeItem7: 'Cocoa',
            coffeeItem8: 'Filter',
        },
        events: {
            title: 'Events',
            text: 'Our space is suitable for the organization of private and public events, workshops and other activities. We are open to suggestions and will be happy to cooperate. We look forward to hearing from you at <0>info@creativecommunity.space</0> or direct to our <1>instagram</1>.'
        },
        about: {
            title: 'About us',
            text: '"Letters and Numbers" are a team of four people who met in Yerevan and didn`t know each other before we moved here. How did we get the idea to open a coffee shop? People in Armenia love and drink a lot of coffee, but there weren`t many places where you could drink delicious and high quality coffee. In the search for a suitable location, we decided to open a coffee shop in the format of "coworking + coffee shop", because in the city is clearly lack of places where you can sit with a laptop for personal or work matters. When discussing name options we remembered that in the Armenian alphabet letters can act as both letters and numbers at the same time, and all our communication is essentially a flow of numbers and letters. We are in a quite unique location: we are in the very center, but we are hidden from the roar of cars, we have our own small garden and swimming pool. And the location itself obliges: once it was the studio of architect Varazdat Arevshatyan, after more than 20 years the restaurant "At Gayane". We still have many regular visitors, including guests from distant countries. In general, we got a room with history, so we did not completely mop it up, but rethought and made an updated version of this place. We already have their own feature. For example, the "five o`clock". Nearby is the bell tower of the Church of St. Zoravor, which rings every 5 pm. You don`t even have to watch the clock.'
        },
        footer: {
            follow: 'FOLLOW US'
        },
        freeDay: {
            title: 'Letters and numbers | Free coworking | Coworking | Yerevan',
            description: ''
        }
    }
}

export default en;
