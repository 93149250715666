import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Gallery, { PhotoProps } from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { SectionInfo, SubTitle, PriceTable, CoworkingOptionsTable } from '../';
import './Coworking.css';

const Coworking = () => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event: MouseEvent, { photo, index }: { photo: PhotoProps, index: number }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = [
    {
      src: "images/opt/coworking-space-min.jpg",
      width: 2,
      height: 3,
      alt: 'Coworking space'
    },
    {
      src: "images/opt/individual-workplace-min.jpg",
      width: 2,
      height: 3,
      alt: 'Individual workplace'
    },
    {
      src: "images/opt/quiet-workplace-min.jpg",
      width: 2,
      height: 3,
      alt: 'Quiet workplace'
    },
    {
      src: "images/opt/coworking-min.jpg",
      width: 2,
      height: 3,
      alt: 'Coworking'
    },
    {
      src: "images/opt/comfortable-workplace-min.jpg",
      width: 2,
      height: 3,
      alt: 'Comfortable workplace'
    },
    {
      src: "images/opt/creative-environment-min.jpg",
      width: 2,
      height: 3,
      alt: 'Creative environment'
    },
    {
      src: "images/opt/cozy-space-min.jpg",
      width: 2,
      height: 3,
      alt: 'Cozy space'
    },
    {
      src: "images/opt/meeting-room-min.jpg",
      width: 2,
      height: 3,
      alt: 'Meeting room'
    },
  ]

  return (
    <div className="Coworking">
      <h1 className="Coworking__title Coworking__title_center Coworking__title_padding">Coworking</h1>
      
      <section className="Coworking-section Coworking-section__prices">
        <div className="Coworking__table">
          <PriceTable/>
        </div>

        <div className="Coworking__tables">
          <CoworkingOptionsTable/>
        </div>
      </section>
      
      <section className="Coworking-section Coworking-section__location">
          <div className="location-map"></div>
          <SectionInfo
            title={() => <SubTitle>{t('coworking.title1')}</SubTitle>} 
            color="var(--brand-color-1)"
            description={t('coworking.desc1') ?? ''}
          />
      </section>
      
      <section className="Coworking-section Coworking-section__amenities">
        <div className="amenities">
          <SectionInfo
            title={() => <SubTitle>{t('coworking.title1')}</SubTitle>} 
            color="var(--brand-color-1)"
            description={t('coworking.desc2') ?? ''}
          />
          <SectionInfo
            title={() => <SubTitle>{t('coworking.title3')}</SubTitle>} 
            color="var(--brand-color-2)"
            description={t('coworking.desc3') ?? 'z'}
          />
        </div>
        <div className="amenities-slider">
        {/* 
        // @ts-ignore */}
        <Gallery photos={photos} onClick={openLightbox} direction={"column"}/>
        {/* 
        // @ts-ignore */}
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                // @ts-ignore
                views={photos.map(x => ({
                  ...x,
                  // @ts-ignore
                  srcset: x.srcSet,
                  // @ts-ignore
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        </div>
      </section>
    </div>
  );
}

export default Coworking;
