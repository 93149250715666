import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Nav, Navbar, Link as CustomLink, Social } from '../';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <footer className="Footer">
      <section className="navigation__section">
        <h3 className="navigation__section-title">Contacts</h3>
        <div className="navigation__navbar navigation__links">
          <p className="info-desc">Yerevan, 35G Tumanyan St</p>
          <div><CustomLink href={'mailto:' + t('events.email')} alt="email link" className="email-link">info@creativecommunity.space</CustomLink></div>
          <div><CustomLink href="https://t.me/lan_yerevan" target="_blank" alt="telegram link" className="email-link">https://t.me/lan_yerevan</CustomLink></div>
          <div><CustomLink href="tel:+374 94 601 303" alt="phone link" className="email-link">+374 94 601 303</CustomLink></div>
        </div>
      </section>
      <section className="navigation__section">
        <h3 className="navigation__section-title">Menu</h3>
        <Navbar className="navigation__navbar" vertical>
          <Link to="home" alt="home link" spy={true} smooth={true} duration={500} className="element" offset={-100}>
            <Nav value={t('nav.home')}/>
          </Link>
          
          <Link to="coworking" alt="coworking link" spy={true} smooth={true} duration={500} className="element"  offset={-100}>
            <Nav value={t('nav.coworking')}/>
          </Link>
            
          <Link to="coffeeshop" alt="coffeeshop link" spy={true} smooth={true} duration={500} className="element" offset={-100}>
            <Nav value={t('nav.coffeeshop')}/>
          </Link>

          <Nav value={t('nav.events')} onClick={() => navigate('/events')}/>

          <Link to="about" alt="about link" spy={true} smooth={true} duration={500} className="element" offset={-100}>
            <Nav value={t('nav.about')}/>
          </Link>

          <Link to="contacts" alt="contacts link" spy={true} smooth={true} duration={500} className="element" offset={-100}>
            <Nav value={t('nav.contacts')}/>
          </Link>
        </Navbar>
      </section>

      <section className="navigation__section">
        <h3 className="navigation__section-title">{t('footer.follow')}</h3>
        <div className="navigation__navbar">
          <Social/>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
