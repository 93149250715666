import { About } from '../../components';

import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="AboutPage">
      <About/>
    </div>
  );
}

export default AboutPage;
