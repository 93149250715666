import { Button } from '../../components';
import './NotFoundPage.css';

const NotFoundPage = () => {
  return (
    <div className="NotFoundPage NotFoundPage-content NotFoundPage_bg">
        <h1 className="NotFoundPage-title NotFoundPage-text">404</h1>
        <h1 className="NotFoundPage-desc NotFoundPage-text">Page not found</h1>
        <Button to="/">Go to Home</Button>
    </div>
  );
}
  
export default NotFoundPage;
  