import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, TextField, Button, Chip } from '@material-ui/core';
import { Event, UniqueEntries, isSoldOut } from '../EventsPage/EventsPage';
import { Footer, Loader } from '../../components';
import { errorNotify, soldOutNotify, submitNotify, fetchRetry, numWord } from '../../utils';
import './EventPage.css';

const EventPage = () => {
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState<Event>();
    const [uniqueEntries, setUniqueEntries] = useState<UniqueEntries>({});
    const navigate = useNavigate();

    let { eventId } = useParams();

    const validationSchema = yup.object({
      name: yup
        .string()
        .required('Требуется имя'),
      email: yup
        .string()
        .email('Введите действительный адрес электронной почты')
        .required('Требуется электронная почта'),
      phone: yup
        .number()
        .required('Требуется телефон'),
    });

    const formik = useFormik({
      initialValues: {
        name: '',
        email: '',
        phone: '',
        numberOfPersons: 1,
        telegram: '',
        comment: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        submitForm(values);
      },
    });

    const submitForm = async (values: any) => {
      try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/api/entries`, {
          method: 'POST',
          body: JSON.stringify({
            name: values.name,
            email: values.email,
            phone: values.phone,
            numberOfPersons: String(values.numberOfPersons),
            telegram: values.telegram === undefined ? '' : `@${values.telegram}`,
            date: event?.name,
            eventId: event?.id, 
            comment: values.comment,
          })
       });
        formik.handleReset(() => {});

        submitNotify();
        
        getUniqueEntries();
      } catch (err) {
        errorNotify();
        console.warn(err);
      }
    }

    const getEvent = async () => {
      const response = await fetchRetry(`${process.env.REACT_APP_BASE_URL}/api/event?eventId=${eventId}`);

      if (!response.ok) {
        if (response.status === 404) {
          navigate(`/events`);
          return;
        }

        throw new Error('getEvent');
      }
  
      const data = await response.json();

      const ev = {
        ...data,
        capacity: !data.capacity ? 0 : Number(data.capacity)
      }

      setEvent(ev);
      setLoading(false)

      return ev;
    }

    const getUniqueEntries = async () => {
      const response = await fetchRetry(`${process.env.REACT_APP_BASE_URL}/api/entries/unique`);
      if (!response.ok) {
        throw new Error('[getUniqueEntries] is not valid response');
      }

      const data = await response.json();
     
      setUniqueEntries(data);
      
      return data;
    }

    const getMaxCount = (): number => {
      if (!event) {
        return 0;
      }

      if (!uniqueEntries[event.id]) {
        return event.capacity;
      }

      return event.capacity - uniqueEntries[event.id];
    }

    const init = async () => {
      if (loading) {
        try {
          const [event2, entries2] = await Promise.all([
            getEvent(),
            getUniqueEntries(), 
          ]);
    
          if (isSoldOut(event2, entries2)) {
            soldOutNotify();
          }
    
          setLoading(false);
        } catch(err: any) {
          if (err.name === 'AbortError') {
            console.warn(err);
            return;
          };

          errorNotify();
          console.warn(err);
          navigate('/error');
        }
      }
    }

    useEffect(() => {
      init();
    }, [loading, setLoading])

    useEffect(() => {
      const intervalId = setInterval(() => {        
        if (event) {
          try {
            getUniqueEntries();
          } catch(err: any) {
            if (err.name === 'AbortError') {
              console.warn(err);
              return;
            };

            errorNotify();
            console.warn(err);
          }
        }
      }, 10000);
  
      return () => {
        clearInterval(intervalId);
      }
    }, [event, uniqueEntries])

    const handleChangeNumberOfPersons = (value: React.ChangeEvent<any>) => {
      if (Number(value.target.value) > getMaxCount()) {
        return;
      }

      formik.handleChange(value)
    }

    return (
      <>
        {
          loading ? <Loader color="var(--brand-color-1)"/> :
          <div className="EventPage">
            <header className="EventPageHeader">
            </header>

            <div className="EventPageTitle">
              { isSoldOut(event, uniqueEntries) ? <Chip label="Sold out" color="secondary"/> : null }
              <h1 className="EventPageLabel" dangerouslySetInnerHTML={
                { __html: `${event ? event.description : ''}` }
              }/>
            </div>
          
            <div className="EventPageFormBlock">
              <div className="EventPageFormBlockWrapper">              
                {event && event.img ? <div className="EventPageImg" dangerouslySetInnerHTML={
                  { __html: event.img }
                }/> : ''}



                <div className="EventPageFormWrapper">
                  <p className="EventPageText">🗓️ {event?.name}</p>
                  <p className="EventPageText">🔍 Узнать подробнее про <Link href={event?.link} target="_blank">мероприятие</Link></p>

                  {event?.externalLink ? <Link className="EventPageExternalLink" href={event.externalLink} target="_blank">👉 Зарегистрироваться на мероприятие</Link> : <form className="EventPageForm" onSubmit={formik.handleSubmit}>    
                    <TextField
                      variant="outlined"
                      disabled={isSoldOut(event, uniqueEntries)}
                      fullWidth
                      id="name"
                      name="name"
                      label="Имя"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
          
                    <TextField
                      variant="outlined"
                      disabled={isSoldOut(event, uniqueEntries)}
                      fullWidth
                      id="email"
                      name="email"
                      label="Электронная почта"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
          
                    <TextField
                      variant="outlined"
                      disabled={isSoldOut(event, uniqueEntries)}
                      fullWidth
                      id="phone"
                      name="phone"
                      label="Телефон"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
          
                    <div className="EventPageFieldGroup">
                      <TextField
                        variant="outlined"
                        disabled={isSoldOut(event, uniqueEntries)}
                        fullWidth
                        inputProps={{ min: 1, max: getMaxCount() }}
                        maxRows={2}
                        id="numberOfPersons"
                        name="numberOfPersons"
                        label="Количество гостей"
                        type="number"
                        value={formik.values.numberOfPersons}
                        onChange={handleChangeNumberOfPersons}
                        onBlur={formik.handleBlur}
                        error={formik.touched.numberOfPersons && Boolean(formik.errors.numberOfPersons)}
                        helperText={<p className="NumberOfPersonsHint">{getMaxCount() < 10 && getMaxCount() !== 0 ? `Осталось ${getMaxCount()} ${numWord(getMaxCount(), ['место', 'места', 'мест'])} ` : ''}</p>}
                      />
            
                      <TextField
                        variant="outlined"
                        disabled={isSoldOut(event, uniqueEntries)}
                        fullWidth
                        id="telegram"
                        name="telegram"
                        label="Telegram"
                        value={formik.values.telegram}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.telegram && Boolean(formik.errors.telegram)}
                        helperText="Если нет телеграмма оставьте контакт по которому мы можем с вами связаться в комментарии"
                      />
                    </div>
          
                    <TextField
                      variant="outlined"
                      disabled={isSoldOut(event, uniqueEntries)}
                      fullWidth
                      id="comment"
                      name="comment"
                      label="Комментарий"
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.comment && Boolean(formik.errors.comment)}
                      helperText={formik.touched.comment && formik.errors.comment}
                    />
          
                    <Button
                      disabled={isSoldOut(event, uniqueEntries)}
                      className="EventPageFormButton" 
                      color="primary" 
                      variant="contained" 
                      fullWidth 
                      type="submit"
                    >
                      Отправить
                    </Button>

                    <p className="EventPageTextMin">Для отмены регистрации - напишите нам в <Link href="https://t.me/lan_yerevan" target="_blank">telegram</Link></p>
                  </form>}
                </div>
              </div>
            </div>
          </div>
        }
        { loading ? '' : <Footer /> }
      </>
);
}
    
export default EventPage;
