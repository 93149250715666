import SocialIcon from '../SocialIcon/SocialIcon';
import InstagramIcon from '../../assets/instagram.png';
import TelegramIcon from '../../assets/telegram.png';
import './Social.css';

const Social = () => {
    return (
        <div className="Social">
            <SocialIcon src={InstagramIcon} alt="instagram" href="https://www.instagram.com/lan_yerevan/"/>
            <SocialIcon src={TelegramIcon} alt="telegram" href="https://t.me/lan_yerevan"/>
            {/* <SocialIcon src={TwitterIcon} alt="twitter" href="https://twitter.com/LAN_Yerevan"/> */}
        </div>
    )
}

export default Social