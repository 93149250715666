import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Chip, Link } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Footer, Loader } from '../../components';
import { errorNotify, fetchRetry } from '../../utils';
import './EventsPage.css';

export interface Event {
  capacity: number;
  date: string
  description: string
  externalLink: string
  id: string
  img: string
  link: string
  name: string
  showForm: boolean
  type: string
}

export interface EventType {
  type: string;
  groups: EventGroup[];
}

export interface EventGroup {
  date: string;
  events: Event[];
}

export type UniqueEntries = {[key: string]: number};

function formatDate(date: string): string[] {
  const d = date.split('.');

  const date2 = new Date(Number(d[2]), Number(d[1]) < 10 ? Number(d[1]) * 1 - 1 : Number(d[1]) - 1, Number(d[0]));
  
  const month = date2.toLocaleString('default', { month: 'short' });
  const day = date2.toLocaleString("en", { weekday: 'long' });

  return [month, d[0], day];
}

function getDate(date: string): string {
  const [month, day, dayName] = formatDate(date);
  return `${month} ${day}`
}

function getWeekday(date: string): string {
  const [month, day, dayName] = formatDate(date);
  return `${dayName}`
}

function getMonthFromDate(date: string): string {
  return formatDate(date)[0];
}

function getDayFromDate(date: string): string {
  return formatDate(date)[1];
}


function getTimeFromDate(date: string): string {
  const d = date.split('.');

  return d.length === 2 ? d[1] : '';
}

export const isSoldOut = (event: Event | undefined, uniqueEntries: UniqueEntries): boolean => {
  if (!event) {
    return false;
  }

  const countOfRegistred = uniqueEntries[event.id] ?? 0;

  if (countOfRegistred === 0) {
    return false;
  }

  return countOfRegistred >= event.capacity;
}

const EventsPage = () => {
  const [loading, setLoading] = useState(true);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [uniqueEntries, setUniqueEntries] = useState<UniqueEntries>({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goTo = (eventId: string) => {
    navigate(`/events/${eventId}`);
  }

  const getUniqueEntries = async () => {
    const response = await fetchRetry(`${process.env.REACT_APP_BASE_URL}/api/entries/unique`);
    if (!response.ok) {
      throw new Error('[getUniqueEntries] is not valid response');
    }

    const data = await response.json();
    
    setUniqueEntries(data);
  }

  const getEvents = async () => {
    const response = await fetchRetry(`${process.env.REACT_APP_BASE_URL}/api/events`);
    if (!response.ok) {
      throw new Error('[getEvents] is not valid response');
    }

    const data = await response.json();
    const uniqueEventTypes: string[] = [];
    const eventTypes: EventType[] = [];

    data.forEach((d: Event) => {
      if (!uniqueEventTypes.includes(d.type)) {
        uniqueEventTypes.push(d.type);
        eventTypes.push({
          type: d.type,
          groups: []
        });
      }
    })

    eventTypes.forEach(eventType => {
      const tmp: {[date: string]: Event[]} = {};

      data.forEach((e: Event) => {
        if (eventType.type === e.type) {
          const ev = {
            ...e,
            capacity: !e.capacity ? 0 : Number(e.capacity)
          }

          if (!tmp[ev.date]) {
            tmp[ev.date] = [ev];
          } else {
            tmp[ev.date].push(ev);
          }
        }
      })

      Object.keys(tmp).forEach(date => {
        eventType.groups.push({date: date, events: tmp[date]});
      })
    })

    setEventTypes(eventTypes);
  }
  
  const init = async () => {
    try {
      await Promise.all([
        getEvents(),
        getUniqueEntries(),
      ]);
  
      setLoading(false);
    } catch(err: any) {
      if (err.name === 'AbortError') {
        console.warn(err);
        return;
      };

      errorNotify();
      console.warn(err);
      navigate('/error');
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        getUniqueEntries()
      } catch(err: any) {
        if (err.name === 'AbortError') {
          console.warn(err);
          return;
        };

        errorNotify();
        console.warn(err);
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  useEffect(() => {
    if (loading) {
      init()
    }
  }, [loading, setLoading])

  return (
    <>
      <Helmet>
        <title>{t('head.title')}</title>
        <meta name="description" content={t('head.description') ?? ''}/>
        <link rel="canonical" href="https://lettersandnumbers.am/events" />
      </Helmet>
      {
        loading ? <Loader color="var(--brand-color-1)"/> :
        <div className="EventsPage EventsPage_bg">
          <div className="EventTypes">
          {
            eventTypes.map(e => (
              <div className="EventType" key={e.type}>
                <div>
                <h1 className="EventTypeTitle">{e.type}</h1>
                </div>

                {e.groups.map((eventGroup, index) => (
                    <div className="EventsGroup" key={e.type + eventGroup.events.length + index}>
                      <div className="EventsBox EventsDate">
                        <h1 className="EventsDate EventsDateMonth">{getWeekday(eventGroup.date)}</h1>
                        <h1 className="EventsDate EventsDateMonth">{getDate(eventGroup.date)}</h1>
                      </div>
                      
                      <div className="EventsWrapper">
                        <ul className="Events">
                          {
                            eventGroup.events.map(event => (
                              <li className="Event EventLink" key={event.id}>
                                {isSoldOut(event, uniqueEntries) ? <Chip label="Sold out" color="secondary"/> : ''}
                                <Link onClick={() => goTo(event.id)} className="EventDesc" dangerouslySetInnerHTML={
                                  { __html: `${getTimeFromDate(event.name)} ${event.description}` }
                                }/>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                  </div>
                ))}
              </div>
            ))
          }
          </div>
      </div>
      }
      { loading ? '' : <Footer /> }
    </>
  );
}
  
export default EventsPage;
  