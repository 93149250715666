import { useTranslation } from 'react-i18next';
import './CoworkingOptionsTable.css';

const CoworkingOptionsTable = () => {  
    const { t } = useTranslation();

    return (
     <div className="CoworkingOptionsTable">
        {/* <h2 className="Coworking__title Coworking__title_center PriceTable__title">{t('coworking.coworkingOptions.title')}</h2> */}


        <div className="PriceTable PriceTable_padding">
            <div className="PriceTable__head">
                <div className="PriceTable__col PriceTable__col_tech col-content_center">
                    <div className="PriceTable__head__row"><h2 className="head__title PriceTable__title">{t('coworking.coworkingOptions.title')}</h2></div>
                </div>
            </div>
            <div className="PriceTable__body">
                <div className="PriceTable__col PriceTable__col_tech col-content_center PriceTable__text PriceTable__text-color_white">
                    <div className="PriceTable__row  PriceTable__row_tech PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col1.title')}
                    </div>
                    <div className="PriceTable__row  PriceTable__row_tech PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col2.title')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_tech PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col3.title')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_tech PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col4.title')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_tech PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col5.title')}
                    </div>
                </div>

                <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__col_first PriceTable__text-color_blue">
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col1.row1')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col2.row1')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col3.row1')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col4.row1')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col5.row1')}
                    </div>
                </div>

                <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col1.row2')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col2.row2')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col3.row2')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col4.row2')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col5.row2')}
                    </div>
                </div>

                <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__col_last PriceTable__text-color_blue">
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col1.row3')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col2.row3')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col3.row3')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col4.row3')}
                    </div>
                    <div className="PriceTable__row PriceTable__row_fixed-height">
                        {t('coworking.coworkingOptions.col5.row3')}
                    </div>
                </div>
            </div>
        </div>
     </div>
    )
}

export default CoworkingOptionsTable;