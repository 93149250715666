import { ReactComponent as AppLogo } from '../../assets/logo.svg';
import './Logo.css';

interface LogoProps {
  className: string;
}

const Logo = ({ className }: LogoProps) => {
  return (
    <AppLogo className={"Logo " + className}/>
  );
}

export default Logo;
